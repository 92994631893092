import axios from "axios";

class FMS {

    getHttpProd(authorization){
        return axios.create({
            baseURL: "https://fms-api-p6ln722mzq-uc.a.run.app/aerialoop/api/v1/fms",
            headers: {
                "Content-type": "application/json",
                "Authorization": authorization
            }
        })
    }

    getHttpDev(authorization){
        return axios.create({
            baseURL: "https://staging-fms-api-p6ln722mzq-uc.a.run.app/aerialoop/api/v1/fms",
            headers: {
                "Content-type": "application/json",
                "Authorization": authorization
            }
        })
    }

    getHttpLocal(authorization){
        return axios.create({
            baseURL: "http://127.0.0.1:8080/aerialoop/api/v1/fms",
            headers: {
                "Content-type": "application/json",
                "Authorization": authorization
            }
        })
    }

    getEnvironment(authorization, staging_production){
        var http;
        if(staging_production === "staging"){
            http = this.getHttpDev(authorization)
        }
        else if(staging_production === "local"){
            http = this.getHttpLocal(authorization)
        }
        else{
            http = this.getHttpProd(authorization)
        }
        return http
    }

    async getOrderStatus(order_id, authorization, staging_production){
        var http = this.getEnvironment(authorization, staging_production)
        var result = await http.get(`logistics/order/batch/`+order_id)
        return result.data
    }
}

export default new FMS();